import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, Checkbox, Grid, Paper, Slider, TextField, Tooltip, Typography } from "@mui/material";
import Form from "../components/Form";
import FieldContainer from "../components/Fields/FieldContainer";
import FieldControl from "../components/Fields/FieldControl";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import LoadingAnimation from "../components/LoadingAnimation";
import jsAPI from '../helpers/jsAPI';
import common from '../helpers/common';
import UserContext from '../context/UserContext';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";
import ConfirmationDialog from "../components/ConfirmationDialog";
import obj from "../theme/palette";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import InfoIcon from '@mui/icons-material/Info';


const ProgressBar = ({ value }) => {
  const getProgressColor = (value) => {
    if (value >= 90) return '#32CD32'; // Lime green for 90% and above
    if (value >= 70) return '#228B22'; // Medium dark green for 70% and above
    if (value >= 50) return '#006400'; // Dark green for 50% and above
    
    return '#34bb8b'; // Lighter green for less than 50%
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
      <Box sx={{ width: '100%', background: '#b1e5d2', borderRadius: '4px', height: '8px', overflow: 'hidden', position: 'relative' }}>
        <Box style={{ width: `${value}%`, background: getProgressColor(value), height: '100%' }} />
      </Box>
      <Typography>{Math.round(value)}%</Typography> 
    </Box>
  )
}

const OngoingEvaluationForm = (props) => {
  const {openForm, onClose, detailData, selectedTab} = props; 
    
  const history = useHistory();
  const [user] = useContext(UserContext);
  const routerParams = useParams();

  const entryPath = `/${user.client_code}/app/performance_management/approved_evaluations`;
  const newPath = `${entryPath}/new`;
  // const openPath = `${entryPath}/${routerParams.id}`;
  const editPath = `${entryPath}/edit/${routerParams.eval_request_id}/${routerParams.employee_id}`;

  const editAccessible = common.isAccessible(user.accessKeys, "performance_management.edit");

  const defaultFormData = useRef({
    code: null,
    full_name: "",
    position_name: "", 
    sup_org_name: "",
    classes: [],
    goals_with_no_class: [],
    total_employee_progress: 0,
    total_evaluator_progress: 0,
    employee_comments: "",
    evaluator_comments: "",
    evaluator_strength: "",
    evaluator_weakness: "",
    employee_strength: "",
    employee_weakness: "",
  })

  const [loading, setLoading] = useState(false);
  const [formMode, setFormMode] = useState("view");
  const [formData, setFormData] = useState({ ...defaultFormData.current });
  const [evalRequestId, setEvalRequestId] = useState(null);
  const [goalProgress, setGoalProgress] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [pmsCreatedBy, setPmsCreatedBy] = useState(null);
  const [isAllowModification, setIsAllowModification] = useState(false);
  const [isAllowStrAndWeakEmployee, setIsAllowStrAndWeakEmployee] = useState(false);
  const [isAllowStrAndWeakEvaluator, setIsAllowStrAndWeakEvaluator] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [confirmationInfo, setConfirmationInfo] = useState({ action_id: 0, message: "", show: false});




  const handleDoneEval = async () => {    
    setLoading(true);
    try { 
      if (formMode === "edit") {
        const responseJSON = await jsAPI.request('/api/ongoing_evaluation_requests', {
          method: 'PUT',
          body: JSON.stringify({ 
            form_data: formData, 
            employee_id: routerParams.employee_id, 
            evaluation_request_id: routerParams.eval_request_id  
          })
        })
  
        if (!responseJSON.success) {        
          throw new Error(responseJSON.error_message);
        }
      }

      const responseJSON = await jsAPI.request('/api/ongoing_evaluation_requests', {
        method: 'PUT',
        body: JSON.stringify({ 
          form_data: formData, 
          employee_id: routerParams.employee_id || routerParams.params, 
          evaluation_request_id: routerParams.eval_request_id || evalRequestId,
          is_done: true
        })
      })

      if (responseJSON.success) {
        setSuccessMessage("Congratulations! This is evaluation is done.")
        setShowSuccess(true)
        loadData();
        setFormMode("view")
        history.push(`/${user.client_code}/app/performance_management/approved_evaluations/${routerParams.employee_id || routerParams.params}`)
      }
      else {
        setErrorMessage("Something went wrong please try again later.")
        throw new Error("Something went wrong please try again later.")
      }
    } 
    catch (error) {
      setErrorMessage(error.message)
      return;
    }
    finally {

    }
  }

  const handleAffirmDoneEval = () => {
    handleDoneEval();
  }

  const handleEdit = () => { 
    const currentYearDate =  moment();
    const coverageStartDate = moment(formData.coverage_start, "MM/DD/YYYY");

    // this will false, unless current date >= coverage start date
    if (coverageStartDate.isBefore(currentYearDate)) {
      history.push(`${entryPath}/edit/${evalRequestId}/${routerParams.params}`);
      setFormMode("edit")
    }
    else {
      setErrorMessage("You cannot edit the evaluation form if the coverage start date is earlier than today.");
    }
  }

  const handleSave = async () => {    
    try {      
      const responseJSON = await jsAPI.request('/api/ongoing_evaluation_requests', {
        method: 'PUT',
        body: JSON.stringify({ 
          form_data: formData, 
          employee_id: routerParams.employee_id, 
          evaluation_request_id: routerParams.eval_request_id  
        })
      })

      if (!responseJSON.success) {        
        throw new Error(responseJSON.error_message);
      }
      
      history.push(`${entryPath}/${routerParams.params || routerParams.employee_id}`);
      setFormMode("view");
    } 
    catch (error) {      
    }
  }

  const handleCancel = () => {
    setFormMode("view");
    history.push(`${entryPath}/${routerParams.employee_id}`);
  }

  const handleGetRemakrs = (score) => {
    if (formData.scoring) {
      for (let i = 0; i < formData.scoring.length; i++) {
        if (score >= formData.scoring[i].fromRange && score <= formData.scoring[i].toRange) {
          return {
            score: formData.scoring[i].score,
            remark: formData.scoring[i].remark
          }
        }
      }
    }

    return "Invalid Score"
  } 


  const loadData = useCallback(async () => {
    setLoading(true);
    try {          
      if (evalRequestId) {
        const responseJSON = await jsAPI.request("/api/evaluation_requests/get_ongoing_employee_eval_details?" + new URLSearchParams({
          eval_request_id: evalRequestId,
          employee_id: routerParams.params || routerParams.employee_id
        }));

        if (responseJSON.result.length > 0) {                            
          setFormData(prev => ({
            ...prev,
            ...responseJSON.result[0]
          }))
        }

        if (responseJSON.goalProgress.length > 0) {
          setGoalProgress(responseJSON.goalProgress)
        }

        const {employee_comment, evaluator_comment} = responseJSON.comments;
        const {employeeStrengths, employeeWeaknesses, evaluatorStrengths, evaluatorWeaknesses} = responseJSON;

        setFormData(prev => ({
          ...prev,
          employee_comments: employee_comment || "",
          evaluator_comments: evaluator_comment || "",
          evaluator_strength: evaluatorStrengths || "", 
          evaluator_weakness: evaluatorWeaknesses || "",
          employee_strength: employeeStrengths || "",
          employee_weakness: employeeWeaknesses || ""
        }))
      }
    } 
    catch (error) {

    }
    finally {
      setLoading(false);
    }
  }, [formMode, routerParams.params, evalRequestId])

  useEffect(() => {
    loadData();
  }, [loadData])

  useEffect(() => {        
    if (detailData.length > 0) {            
      setEvalRequestId(detailData[0].eval_request_id)
      setPmsCreatedBy(detailData[0].created_by)
      setIsAllowModification(detailData[0].is_allow_modification)
      setIsAllowStrAndWeakEmployee(detailData[0].is_enable_str_and_weakness_employee)
      setIsAllowStrAndWeakEvaluator(detailData[0].is_enable_str_and_weakness_evaluator)
    }
  }, [detailData])

  useEffect(() => {
    setFormData(prev => {
      const goalMap = goalProgress.reduce((acc, goal) => {
        acc[goal.goal_id] = {
          employee_progress: goal.employee_progress,
          evaluator_progress: goal.evaluator_progress,
        };
        return acc;
      }, {});
    
      const _prev = { ...prev };
    
      // Update goals_with_no_class
      for (const gwnc of _prev.goals_with_no_class) {
        if (goalMap[gwnc.goal_id]) {
          gwnc.employee_progress = goalMap[gwnc.goal_id].employee_progress;
          gwnc.evaluator_progress = goalMap[gwnc.goal_id].evaluator_progress;
        }
      }
    
      // Update classes and their goals
      for (const c of _prev.classes) {
        for (const g of c.goals) {
          if (goalMap[g.goal_id]) {
            g.employee_progress = goalMap[g.goal_id].employee_progress;
            g.evaluator_progress = goalMap[g.goal_id].evaluator_progress;
          }
        }
      }
    
      return _prev;
    });

    let totalWeight = 0;
    let totalEmployeeProgress = 0
    let totalEvaluatorProgress = 0
    for (const gp of goalProgress) {
      totalWeight += gp.goal_weight
      totalEmployeeProgress += gp.goal_weight * (gp.employee_progress / 100)
      totalEvaluatorProgress += gp.goal_weight * (gp.evaluator_progress / 100)
    }

    totalEmployeeProgress = parseInt((totalEmployeeProgress / totalWeight) * 100) 
    totalEvaluatorProgress = parseInt((totalEvaluatorProgress / totalWeight) * 100) 

    setFormData(prev => {
      let _prev = { ...prev };
      _prev.total_employee_progress = totalEmployeeProgress;
      _prev.total_evaluator_progress = totalEvaluatorProgress;
      return _prev;
    })
  }, [goalProgress])
  

  return (
    <>
      <ConfirmationDialog
        open={confirmationInfo.show}
        onClose={() => {setConfirmationInfo(prev => ({ ...prev, show: false}))}}
        message={confirmationInfo.message}
        onDeny={() => {setConfirmationInfo(prev => ({ ...prev, show: false}))}}
        onAffirm={handleAffirmDoneEval}
      />
      <Form
        open={openForm}
        onClose={onClose}
        title="Employee Evaluation Form"
        secondaryTitle={
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography>Coverage from {formData.coverage_start} to {formData.coverage_end}</Typography>
            {/* check if evaluation end date is 3 days from now */}
            {moment(formData.evaluation_end, 'MM/DD/YYYY').diff(moment().startOf("day"), 'days') >= 0 && moment(formData.evaluation_end, 'MM/DD/YYYY').diff(moment().startOf("day"), 'days') <= 3 && (
              <Card sx={{width: "50%", backgroundColor: obj.warning.light, padding: 0.5}}>
                <CardContent sx={{display: "flex", gap: 3, alignItems: "center", padding: "0 !important"}}>
                  <WarningAmberOutlinedIcon sx={{margin: 0, padding: 0}}/>
                  <Typography>
                    The evaluation will enter a 15-day grace period starting on {moment(formData.evaluation_end, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY')}.
                  </Typography>
                </CardContent>
              </Card>
            )}
            {/* check if evaluation is on grace period */}
            {moment().isBetween(moment(formData.evaluation_end, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY'), moment(formData.evaluation_end, 'MM/DD/YYYY').add(15, 'days').format('MM/DD/YYYY'), null,'[)') && (
              <Card sx={{width: "56%", backgroundColor: obj.warning.light, padding: 0.5}}>
                <CardContent sx={{padding: "0 !important", display: "flex", gap: 3, alignItems: "center"}}>
                  <WarningAmberOutlinedIcon sx={{margin: 0, padding: 0}}/>
                  <Typography>
                    The evaluation is in a grace period until {moment(formData.evaluation_end, 'MM/DD/YYYY').add(15, 'days').format('MM/DD/YYYY')}. After that, the system will automatically mark it as DONE.
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Box>
        }
        showSuccess={showSuccess}
        onSuccessMessageClose={() => setShowSuccess(false)}
        successMessage={successMessage}
        errorMessage={errorMessage}
        onErrorMessageClose={() => setErrorMessage("")}
        actionBar={(
          <Grid container>
            <Grid item lg={3} md={2} sm={1} />
            <Grid item lg={6} md={8} sm={10} xs={12}>
              <>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  {formMode === "view" && editAccessible && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleEdit}
                      style={{ width: 100, }}
                      disabled={formData.is_done}
                    >
                      EDIT
                    </Button>
                  )}
                  {(formMode === "view" && editAccessible && user.user_id === pmsCreatedBy) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {setConfirmationInfo(prev => ({ ...prev, show: true, message: "Are you sure you want to finish evaluation?"}))}}
                      disabled={formData.is_done}
                    >
                      {formData.is_done ? `THIS EVALUATION IS DONE` : `FINISH EVALUATION`}
                    </Button>
                  )}
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  {(formMode !== "view" && pmsCreatedBy === user.user_id) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {setConfirmationInfo(prev => ({ ...prev, show: true, message: "Are you sure you want to finish evaluation?"}))}}
                      disabled={formData.is_done}
                    >
                      FINISH EVALUATION
                    </Button>
                  )}
                  <div style={{ display: "flex", justifyContent: "flex-end", gap: 2, }}>
                    {(formMode === "edit" || formMode === "new") && (
                      <>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleSave}
                          style={{ width: 100, }}
                        >
                          SAVE
                        </Button>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={handleCancel}
                          style={{ width: 100 }}
                        >
                          CANCEL
                        </Button>
                      </>
                    )}
                  </div>
                </Box>
              </>
            </Grid>
            <Grid item lg={3} md={2} sm={1} />
          </Grid>
        )}
      >
        <LoadingAnimation 
          open={loading}
        />
        <Grid
          container
        >
          <Grid item lg={3} md={2} sm={1} />
          <Grid item lg={6} md={8} sm={10} xs={12}>

          </Grid>
        </Grid>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: 3}}>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center"}}>
            <Box sx={{width: "80%", display: "flex", gap: 3, justifyContent: "center"}}>
              <Box sx={{width: "80%", display: "flex", flexDirection: "column", gap: 3}}>
                <Paper sx={{width: "100%"}}>
                  <Box sx={{display: "flex", gap: 3, alignItems: "center", marginLeft: 10, py: 2}}>
                    <Avatar sx={{height: "5rem", width: "5rem"}}/>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                      <Typography>{formData.full_name}</Typography>
                      <Typography>ID: {formData.code}</Typography>
                      <Typography>{formData.position_name}</Typography>
                      <Typography>{formData.sup_org_name}</Typography>
                    </Box>
                  </Box>
                </Paper>
                <Paper sx={{width: "100%"}}>
                  <Box sx={{marginLeft: 10, py: 2}}>
                    <Typography>{formData.number}</Typography>
                    <Typography>{formData.title}</Typography>
                    <Typography>Evaluation on {formData.evaluation_start} to {formData.evaluation_end}</Typography>
                  </Box>
                </Paper>
              </Box>
              <Paper sx={{width: "20%", px: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly"}}>
                {(user.user_id == pmsCreatedBy && selectedTab !== 'finished evaluations') && (
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="h6">Evaluator Overall Progress</Typography>
                    <ProgressBar value={formData.total_evaluator_progress} />
                  </Box>
                )}    

                {(user.user_id == pmsCreatedBy && selectedTab === 'finished evaluations') && (
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="h1" textAlign="center" color="primary">{formData.total_evaluator_progress}%</Typography>
                    <Box sx={{display: "flex", gap: 2, alignItems: "center", marginBottom: 3}}>
                      <Typography>Evaluator Overall Progress</Typography>
                      <Tooltip title="Evaluator Overall Progress = (Total Evalutor Progress / Total Weight of Goals) * 100 ">
                        <InfoIcon />
                      </Tooltip>
                    </Box>
                    <Typography variant="h5" color="primary">{`Score: ${handleGetRemakrs(formData.total_evaluator_progress).score || ""}`}</Typography>
                    <Typography variant="h5" color="primary">{`Remark: ${handleGetRemakrs(formData.total_evaluator_progress).remark || ""}`}</Typography>
                  </Box>
                )}

                {selectedTab !== "finished evaluations" ? (
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="h6">Employee Overall Progress</Typography>
                    <ProgressBar value={formData.total_employee_progress} />
                  </Box>
                ) : 
                  user.user_id !== pmsCreatedBy && (
                    <Box sx={{ width: "100%" }}>
                      <Typography variant="h1" textAlign="center" color="primary">{formData.total_employee_progress}%</Typography>
                      <Typography textAlign="center" mb={3}>Evaluatee Overall Progress</Typography>
                      <Typography variant="h5" color="primary">{`Score: ${handleGetRemakrs(formData.total_employee_progress).score || ""}`}</Typography>
                      <Typography variant="h5" color="primary">{`Remark: ${handleGetRemakrs(formData.total_employee_progress).remark || ""}`}</Typography>
                    </Box>
                  )
                }
              </Paper>
            </Box>
          </Box>
          <Box sx={{width: "96%", display: "flex", alignItems: "center", flexDirection: "column", gap: 3, height: "auto"}}>
            <Paper sx={{width: "80%", padding: 3, display: "flex", flexDirection: "column", gap: 5}}>
              <Typography variant="h4">Goals</Typography>
              <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                {formData.classes.map((data, index) => (
                  <Paper key={data.class_id} sx={{width: "96%", display: "flex", flexDirection: "column", padding: "18px", gap: "18px"}}>
                    <Typography variant="h5" sx={{}}>{data.class_title}</Typography>
                    <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                      {data.goals.map((goal, gix) => (
                        <Accordion key={goal.goal_id}>
                          <AccordionSummary 
                            expandIcon={<ExpandMoreIcon />}
                            sx={(theme) => ({
                              '& .MuiAccordionSummary-expandIconWrapper': {
                                alignSelf: "flex-start",
                                py: 2
                              },
                              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                transform: 'rotate(180deg)',
                                transition: 'transform 0.3s ease'
                              },
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            })}
                          >
                            <Box sx={{display: "flex", flexDirection: "column", gap: 5, width: "100%", px: 4}}>
                              {formMode === "view" ? (
                                <Box sx={{display: "flex", justifyContent: "space-between", width: "100%" }}>
                                  <Typography variant="h6">{goal.goal_title}</Typography>
                                  <Typography variant="h6">{goal.goal_weight}%</Typography>
                                </Box>
                              ) : (
                                <Box sx={{display: "flex", gap: 3}}>
                                  <TextField
                                    label="Goal Title"
                                    variant="outlined"
                                    size="small"
                                    value={goal.goal_title}
                                    fullWidth
                                    onChange={(e) => {
                                      e.stopPropagation()
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.classes[index].goals[gix].goal_title = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    onClick={(e) => {e.stopPropagation()}}
                                    disabled={user.user_id != pmsCreatedBy || !isAllowModification}
                                  />
                                  <TextField
                                    label="Goal Weight"
                                    variant="outlined"
                                    size="small"
                                    value={goal.goal_weight}
                                    onChange={(e) => {
                                      e.stopPropagation()
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.classes[index].goals[gix].goal_weight = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    onClick={(e) => {e.stopPropagation()}}
                                    disabled={user.user_id != pmsCreatedBy || !isAllowModification}
                                  />
                                </Box>
                              )}
                              <Box sx={{display: "flex", gap: 3}}>
                                <Typography>Employee Progress</Typography>
                                <Slider 
                                  size="small"
                                  valueLabelDisplay="auto"
                                  disabled={formMode === "view" || user.user_id == pmsCreatedBy}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}
                                  value={goal.employee_progress}
                                  onChange={(e, newValue) => {
                                    setFormData(prev => {
                                      let _prev = { ...prev };
                                      _prev.classes[index].goals[gix].employee_progress = newValue;
                                      return _prev;
                                    })
                                  }}
                                />
                              </Box>
                              {user.user_id == pmsCreatedBy && (
                                <Box sx={{display: "flex", gap: 3}}>
                                  <Typography>Evaluator Progress</Typography>
                                  <Slider 
                                    size="small"
                                    valueLabelDisplay="auto"
                                    disabled={formMode === "view"}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                    }}
                                    value={goal.evaluator_progress}
                                    onChange={(e, newValue) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.classes[index].goals[gix].evaluator_progress = newValue;
                                        let totalWeight = 0; 
                                        let totalEvaluatorProgress = 0
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            totalWeight += g.goal_weight
                                            totalEvaluatorProgress += g.goal_weight * (g.evaluator_progress / 100)
                                          }
                                        }
                                        totalEvaluatorProgress = parseInt((totalEvaluatorProgress / totalWeight) * 100) 
                                        _prev.total_evaluator_progress = totalEvaluatorProgress;
                                        return _prev;
                                      })
                                    }}
                                  />
                                </Box>
                              )}
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{display: "flex", flexDirection: "column", gap: 1, padding: 3, width: "95%", margin: "auto", backgroundColor: "#D9D9D9"}}>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Specific:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.specific_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.specific_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.specific_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.specific}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.classes[index].goals[gix].specific = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Measurable:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.measurable_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.measurable_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.measurable_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.measurable}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.classes[index].goals[gix].measurable = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Achievable:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.achievable_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.achievable_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.achievable_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.achievable}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.classes[index].goals[gix].achievable = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Relevant:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.relevant_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.relevant_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.relevant_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.relevant}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.classes[index].goals[gix].relevant = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Time bound:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.time_bound_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.time_bound_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.time_bound_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.time_bound}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.classes[index].goals[gix].time_bound = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  </Paper>
                ))}
                {formData.goals_with_no_class.length > 0 && (
                  <Paper>
                    <Typography variant="h5">Unclassified Goals</Typography>
                    <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                      {formData.goals_with_no_class.map((goal, index) => (
                        <Accordion key={goal.goal_id}>
                          <AccordionSummary 
                            // expandIcon={<ExpandMoreIcon style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease' }} 
                            // />}
                            expandIcon={<ExpandMoreIcon />}
                            sx={(theme) => ({
                              '& .MuiAccordionSummary-expandIconWrapper': {
                                alignSelf: "flex-start",
                                py: 2
                              },
                              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                transform: 'rotate(180deg)',
                                transition: 'transform 0.3s ease'
                              },
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            })}
                          >
                            <Box sx={{display: "flex", flexDirection: "column", gap: 5, width: "100%", px: 4}}>
                              <Box sx={{display: "flex", gap: 3}}>
                                {formMode === "view" ? (
                                  <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                    <Typography variant="h6">{goal.goal_title}</Typography>
                                    <Typography variant="h6">{goal.goal_weight}%</Typography>
                                  </Box>
                                ) : (
                                  <>
                                    <TextField
                                      label="Goal Title"
                                      variant="outlined"
                                      size="small"
                                      value={goal.goal_title}
                                      fullWidth
                                      onChange={(e) => {
                                        e.stopPropagation()
                                        setFormData(prev => {
                                          let _prev = { ...prev };
                                          _prev.goals_with_no_class[index].goal_title = e.target.value;
                                          return _prev;
                                        })
                                      }}
                                      onClick={(e) => {e.stopPropagation()}}
                                      disabled={user.user_id != pmsCreatedBy || !isAllowModification}
                                    />
                                    <TextField
                                      label="Goal Weight"
                                      variant="outlined"
                                      size="small"
                                      value={goal.goal_weight}
                                      onChange={(e) => {
                                        e.stopPropagation()
                                        setFormData(prev => {
                                          let _prev = { ...prev };
                                          _prev.goals_with_no_class[index].goal_weight = e.target.value;
                                          return _prev;
                                        })
                                      }}
                                      onClick={(e) => {e.stopPropagation()}}
                                      disabled={user.user_id != pmsCreatedBy || !isAllowModification}
                                    />
                                  </>
                                )}
                              </Box>
                              <Box sx={{display: "flex", gap: 3}}>
                                <Typography>Employee Progress</Typography>
                                <Slider 
                                  size="small"
                                  valueLabelDisplay="auto"
                                  disabled={formMode === "view" || user.user_id == pmsCreatedBy}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}
                                  value={goal.employee_progress}
                                  onChange={(e, newValue) => {
                                    setFormData(prev => {
                                      let _prev = { ...prev };
                                      _prev.goals_with_no_class[index].employee_progress = newValue
                                      return _prev;
                                    })
                                  }}
                                />
                              </Box>
                              {user.user_id == pmsCreatedBy && (
                                <Box sx={{display: "flex", gap: 3}}>
                                  <Typography>Evaluator Progress</Typography>
                                  <Slider 
                                    size="small"
                                    valueLabelDisplay="auto"
                                    disabled={formMode === "view"}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                    }}
                                    value={goal.evaluator_progress}
                                    onChange={(e, newValue) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };                                        
                                        _prev.goals_with_no_class[index].evaluator_progress = newValue
                                        let totalWeight = 0; 
                                        let totalEvaluatorProgress = 0
                                        for (const g of _prev.goals_with_no_class) {
                                          totalWeight += g.goal_weight
                                          totalEvaluatorProgress += g.goal_weight * (g.evaluator_progress / 100)
                                        }
                                        totalEvaluatorProgress = parseInt((totalEvaluatorProgress / totalWeight) * 100) 
                                        _prev.total_evaluator_progress = totalEvaluatorProgress;
                                        return _prev;
                                      })
                                    }}
                                  />
                                </Box>
                              )}
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{display: "flex", flexDirection: "column", gap: 1, padding: 3, width: "95%", margin: "auto", backgroundColor: "#D9D9D9"}}>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Specific:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.specific_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.specific_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.specific_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.specific}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.goals_with_no_class[index].specific = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Measurable:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.measurable_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.measurable_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.measurable_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.measurable}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.goals_with_no_class[index].measurable = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Achievable:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.achievable_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.achievable_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.achievable_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.achievable}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.goals_with_no_class[index].achievable = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Relevant:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.relevant_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.relevant_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.relevant_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.relevant}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.goals_with_no_class[index].relevant = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                                <Typography variant="h6">Time bound:</Typography>
                                <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    value={goal.time_bound_sub_text}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    sx={{width: "30%"}}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        for (const c of _prev.classes) {
                                          for (const g of c.goals) {
                                            g.time_bound_sub_text = e.target.value;
                                          }
                                        }

                                        for (const g of _prev.goals_with_no_class) {
                                          g.time_bound_sub_text = e.target.value;
                                        }
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={goal.time_bound}
                                    disabled={formMode === "view" || user.user_id != pmsCreatedBy || !isAllowModification}
                                    onChange={(e) => {
                                      setFormData(prev => {
                                        let _prev = { ...prev };
                                        _prev.goals_with_no_class[index].time_bound = e.target.value;
                                        return _prev;
                                      })
                                    }}
                                    multiline
                                    minRows={4}
                                    sx={{backgroundColor: "white"}}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  </Paper>
                )}
              </Box>
            </Paper>
            {user.user_id == pmsCreatedBy && (
              <Paper sx={{width: "80%", padding: 3, display: "flex", flexDirection: "column", gap: 3}}>
                <Typography variant="h4">Evaluator Fields</Typography>
                <TextField
                  label="Evaluator Comments"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formData.evaluator_comments}
                  onChange={(e) => {
                    setFormData(prev => {
                      let _prev = { ...prev };
                      _prev.evaluator_comments = e.target.value;
                      return _prev;
                    })
                  }}
                  multiline
                  maxRows={10}
                  minRows={5}
                  disabled={formMode === "view"}
                />
                {isAllowStrAndWeakEvaluator && (
                  <>
                    {formMode === "view" ? (
                      <>
                        <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                          <TextField
                            label="Strength"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formData.evaluator_strength}
                            onChange={(e) => {
                              setFormData(prev => {
                                let _prev = { ...prev };
                                _prev.evaluator_strength = e.target.value;
                                return _prev;
                              })
                            }}
                            multiline
                            maxRows={10}
                            minRows={5}
                            disabled={formMode === "view"}
                          />
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                          <TextField
                            label="Weakness"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formData.evaluator_weakness}
                            onChange={(e) => {
                              setFormData(prev => {
                                let _prev = { ...prev };
                                _prev.evaluator_weakness = e.target.value;
                                return _prev;
                              })
                            }}
                            multiline
                            maxRows={10}
                            minRows={5}
                            disabled={formMode === "view"}
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                          <TextField
                            label="Strength"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formData.evaluator_strength}
                            onChange={(e) => {
                              setFormData(prev => {
                                let _prev = { ...prev };
                                _prev.evaluator_strength = e.target.value;
                                return _prev;
                              })
                            }}
                            multiline
                            maxRows={10}
                            minRows={5}
                            disabled={formMode === "view"}
                          />
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                          <TextField
                            label="Weakness"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formData.evaluator_weakness}
                            onChange={(e) => {
                              setFormData(prev => {
                                let _prev = { ...prev };
                                _prev.evaluator_weakness = e.target.value;
                                return _prev;
                              })
                            }}
                            multiline
                            maxRows={10}
                            minRows={5}
                            disabled={formMode === "view"}
                          />
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Paper>
            )}
            <Paper sx={{width: "80%", padding: 3, display: "flex", flexDirection: "column", gap: 3}}>
              {selectedTab === "finished evaluations" && user.user_id === pmsCreatedBy ? (
                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                  <Typography variant="h4">Employee Fields</Typography>
                  <Typography variant="h4" color="primary">{`Percentage: ${formData.total_employee_progress}%`}</Typography>
                  <Typography variant="h4" color="primary">{`Score: ${handleGetRemakrs(formData.total_employee_progress).score || ""}`}</Typography>
                  <Typography variant="h4" color="primary">{`Remark: ${handleGetRemakrs(formData.total_employee_progress).remark || ""}`}</Typography>
                </Box>
              ) : 
                <Typography variant="h4">Employee Fields</Typography>
              }
              <TextField
                label="Employee Comments"
                variant="outlined"
                fullWidth
                size="small"
                value={formData.employee_comments}
                onChange={(e) => {
                  setFormData(prev => {
                    let _prev = { ...prev };
                    _prev.employee_comments = e.target.value;
                    return _prev;
                  })
                }}
                multiline
                maxRows={10}
                minRows={5}
                disabled={formMode === "view" || user.user_id == pmsCreatedBy}
              />
              {isAllowStrAndWeakEmployee && (
                <>
                  {formMode === "view" ? (
                    <>
                      <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                        <TextField
                          label="Strength"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={formData.employee_strength}
                          onChange={(e) => {
                            setFormData(prev => {
                              let _prev = { ...prev };
                              _prev.employee_strength = e.target.value;
                              return _prev;
                            })
                          }}
                          multiline
                          maxRows={10}
                          minRows={5}
                          disabled={formMode === "view"}
                        />
                      </Box>
                      <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                        <TextField
                          label="Weakness"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={formData.employee_weakness}
                          onChange={(e) => {
                            setFormData(prev => {
                              let _prev = { ...prev };
                              _prev.employee_weakness = e.target.value;
                              return _prev;
                            })
                          }}
                          multiline
                          maxRows={10}
                          minRows={5}
                          disabled={formMode === "view"}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                        <TextField
                          label="Strength"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={formData.employee_strength}
                          onChange={(e) => {
                            setFormData(prev => {
                              let _prev = { ...prev };
                              _prev.employee_strength = e.target.value;
                              return _prev;
                            })
                          }}
                          multiline
                          maxRows={10}
                          minRows={5}
                          disabled={formMode === "view" || user.user_id == pmsCreatedBy}
                        />
                      </Box>
                      <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                        <TextField
                          label="Weakness"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={formData.employee_weakness}
                          onChange={(e) => {
                            setFormData(prev => {
                              let _prev = { ...prev };
                              _prev.employee_weakness = e.target.value;
                              return _prev;
                            })
                          }}
                          multiline
                          maxRows={10}
                          minRows={5}
                          disabled={formMode === "view" || user.user_id == pmsCreatedBy}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </Paper>
          </Box>
        </Box>
      </Form>
    </>
  )
}

export default OngoingEvaluationForm;
